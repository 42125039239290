import {Component, input} from '@angular/core';
import {Project} from "../../../../api/auto-gen";
import {Button} from "primeng/button";
import {NgOptimizedImage} from "@angular/common";
import {RouterLink} from "@angular/router";
import {TagModule} from "primeng/tag";

@Component({
  selector: 'app-project-card-component',
  standalone: true,
  imports: [
    Button,
    NgOptimizedImage,
    RouterLink,
    TagModule
  ],
  templateUrl: './project-card-component.component.html',
  styleUrl: './project-card-component.component.scss'
})
export class ProjectCardComponentComponent {
  project = input.required<Project>();
}
