<div class="card-div flex flex-column gap-3">
  <div class="card-header gap-3">
    <p class="card-title text-color font-bold flex-grow-1">{{ project().name }}</p>
    <p-tag [value]="project().item_number" severity="secondary"/>
    <a [routerLink]="project().project_uuid ? [project().project_uuid, 'options'] : null">
      <i class="pi pi-chevron-right"></i>
    </a>
  </div>
  <div class="content flex-grow-1 flex flex-column">
    <p class="options-text">{{ project().project_options?.length ?? 0 }} Options available</p>
    <div class="project-infos flex-grow-1"></div>
    @if (project().image) {
      <img class="project-image" [ngSrc]="project().image!" fill alt="">
    }
    <div class="placeholder-div"></div>
  </div>
</div>


